<template>
  <page-layout :title="$t(pageTitle)" :has-back-button="true">
    <template #header-actions>
      <base-button
        :label="$t('user_management.import_users_page.upload')"
        type="primary"
        :loading="false"
        :disabled="false"
        @click="triggerUploader"
      />
    </template>

    <div id="importUsers">
      <el-alert class="import__alert" type="info" :closable="false" show-icon>
        <span v-html="$t(infoBarContent)" />
      </el-alert>
      <template v-if="!isUpdateExistingUsers">
        <el-checkbox v-model="sendCredentials">
          <span v-html="$t('user_management.import_users_page.credentials_label')" />
        </el-checkbox>
        <div
          class="credentials-hint"
          v-html="$t('user_management.import_users_page.credentials_hint')"
        />
      </template>

      <el-upload
        action
        class="upload-demo"
        drag
        :http-request="handleUpload"
        :file-list="[]"
        accept=".xlsx, .csv"
      >
        <i class="ri-upload-cloud-2-line"></i>
        <div
          class="el-upload__text"
          v-html="$t('user_management.import_users_page.uploader_label')"
        />
        <div
          class="el-upload__tip"
          slot="tip"
          v-html="$t('user_management.import_users_page.uploader_hint')"
        />
      </el-upload>

      <template v-if="validationErrors.length">
        <div class="info">
          <div class="title error" v-html="$t('user_management.import_users_page.error_title')" />

          <div class="message" v-html="$t('user_management.import_users_page.error_title')" />
        </div>

        <el-table row-class-name="table-row" :data="validationErrors" style="max-width: 576px">
          <el-table-column
            prop="fieldInError"
            :label="$t('user_management.import_users_page.field')"
          />
          <el-table-column
            prop="reason"
            :label="$t('user_management.import_users_page.reason')"
          />
          <el-table-column
            prop="sourceLineNumber"
            :label="$t('uploads.upload_error_table.line_number')"
            :width="100"
          />
        </el-table>
      </template>
    </div>
  </page-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UploadService from '@/app/services/UploadService/UploadService'
import PageLayout from '../layouts/PageLayout'

export default {
  name: 'ImportUsers',
  components: {
    PageLayout
  },
  data () {
    return {
      fileList: [],
      validationErrors: [],
      sendCredentials: true
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    isUpdateExistingUsers () {
      return !!this.$route.query.updateExistingUsers
    },
    pageTitle () {
      return this.isUpdateExistingUsers
        ? 'user_management.update_users_page.title'
        : 'user_management.import_users_page.title'
    },
    infoBarContent () {
      return this.isUpdateExistingUsers
        ? 'user_management.update_users_page.info_bar'
        : 'user_management.import_users_page.info_bar'
    }
  },
  methods: {
    ...mapActions({
      processUploading: 'user/processUploading'
    }),
    handleUpload (fileRequest) {
      this.validationErrors = []
      this.isUpdateExistingUsers ? this.handleUpdate(fileRequest) : this.handleImport(fileRequest)
    },
    async handleImport (fileRequest) {
      const formData = new FormData()

      formData.append('file', fileRequest.file)
      formData.append('corporateAccountOspId', this.corporateAccount.ospId)

      try {
        const uploadId = await this.$api.user.uploadUsers(formData)
        const { validationErrors } = await this.$api.user.validateUsers(uploadId)
        if (validationErrors && validationErrors.length) {
          this.validationErrors = validationErrors
        } else {
          await this.processUploading({
            uploadId,
            sendCredentials: this.sendCredentials
          })
        }
      } catch (e) {
        console.log('import error ', e)
      }
    },
    async handleUpdate (fileRequest) {
      const form = {
        file: fileRequest.file,
        request: new Blob(
          [
            JSON.stringify({
              type: 'USER_UPDATE_UPLOAD',
              targetOspId: this.corporateAccount.ospId
            })
          ],
          { type: 'application/json' }
        )
      }
      const uploader = new UploadService(form)
      try {
        this.validationErrors = await uploader.upload()
      } catch (e) {
        console.log('import error ', e)
      }
    },
    triggerUploader () {
      const element = document.getElementsByClassName('el-upload')[0]
      element && element.click()
    }
  }
}
</script>

<style lang="scss"></style>
